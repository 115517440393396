import "./SearchBar.css";
import React from "react";

class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { searchVal: "" };
    }

    onInputChange(event) {
        this.setState({ searchVal: event.target.value });
    }

    onFormSubmit(event) {
        event.preventDefault();
        this.props.onSubmit(event, this.state.searchVal);
    }

    onInputFocus = event => {
        event.target.setSelectionRange(0, this.state.searchVal.length);
    };

    render() {
        return (
            <div className="search-bar ui segment">
                <form
                    className="ui form"
                    onSubmit={this.onFormSubmit.bind(this)}
                >
                    <div className="ui action sb-input-box">
                        <input
                            className="sb-input"
                            type="text"
                            placeholder="Image Search..."
                            value={this.state.searchVal}
                            onChange={this.onInputChange.bind(this)}
                            onFocus={this.onInputFocus}
                        />
                        <button
                            type="button"
                            className="ui sb-button button "
                            onClick={this.onFormSubmit.bind(this)}
                        >
                            Search
                        </button>
                    </div>
                    {/* <input type="text" /> */}
                </form>
            </div>
        );
    }
}

export default SearchBar;
