import axios from "axios";

const axiosConfig = {
    location: "https://api.unsplash.com/",
    auth:
        "Client-ID 66c8f503ff5bebea162d1635e67b59be9a0a2b64211afa7eabea2a465f1f2f6f"
};

export default axios.create({
    baseURL: axiosConfig.location,
    headers: {
        Authorization: axiosConfig.auth
    }
});
