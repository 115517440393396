import React from "react";
import unsplash from "../api/unsplash";
import SearchBar from "./SearchBar";
import ImageList from "./ImageList";

class App extends React.Component {
    state = { images: [] };
    onSearchSubmit = async (event, searchVal) => {
        const response = await unsplash.get("/search/photos", {
            params: {
                query: searchVal,
                per_page: 25
            }
        });
        this.setState({ images: response.data.results });
    };
    render = props => (
        <div className="ui container" style={{ marginTop: "15px" }}>
            <SearchBar onSubmit={this.onSearchSubmit} />
            <ImageList images={this.state.images} />
            Found {this.state.images.length} images
        </div>
    );
}

export default App;
