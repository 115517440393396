import "./ImageCard.css";
import React from "react";

class ImageCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = { spans: 0, description: "", descVisibility: "hidden" };
        this.imageRef = React.createRef();
    }
    setSpans = () => {
        const imageHeight = this.imageRef.current.children[0].clientHeight;
        const descHeight = this.imageRef.current.children[1].clientHeight;

        const spans = Math.ceil((imageHeight + descHeight) / 10);
        this.setState({ spans, descVisibility: "unset" });
    };
    componentDidMount() {
        this.imageRef.current.children[0].addEventListener(
            "load",
            this.setSpans
        );
    }

    render = () => {
        const { description, urls, user } = this.props.image;
        return (
            <div
                style={{ gridRowEnd: `span ${this.state.spans}` }}
                ref={this.imageRef}
            >
                <img alt={description} src={urls.regular} />
                <div
                    className="description"
                    style={{ visibility: this.state.descVisibility }}
                >
                    {user.name + (user.location ? ", " + user.location : "")}
                </div>
            </div>
        );
    };
}

export default ImageCard;
